@import '@styles/variables';

.container {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;


    .dropdownSelector {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 200px;
        padding: 5px 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: white;
        cursor: pointer;
        transition: all 0.3s ease;

        span {
            font-size: 1.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        img {
            width: 20px;
            height: 20px;
            margin-left: 5px;
            transition: transform 0.3s ease;
        }
    }

    .warningMessage {
        font-size: 1rem;
        color: $error;
    }

    .dropdownButton:hover {
        background-color: #cccccc;
    }

    .dropdownMenu {
        position: absolute;
        left: 0;
        width: 100%;
        margin-top: 5px;
        padding: 0;
        margin-top: 40px;
        background-color: white;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        list-style-type: none;
        z-index: 1000;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease, visibility 0.3s ease;


        .dropdownItem {
            padding: 10px;
            border-bottom: 0.5px solid #ccc;
            /* Border at the bottom of each item */
            cursor: pointer;

            &:hover {
                background-color: #f1f1f1;
            }

            &.selected {
                background-color: $cerulean;
                color: white;
            }
        }

        .dropdown-item:last-child {
            border-bottom: none;
        }
    }

    .open {
        visibility: visible;
        opacity: 1;

        img {
            transform: rotate(180deg);
        }
    }
}