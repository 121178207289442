.salesChart {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    color: #333;
    /* Adjust the height to suit your layout */

    .barChart {
        width: 100%;
        flex-direction: column;
        min-height: 250px;
    }

    .chartHeader {
        display: flex;
        justify-content: space-between;

        .chartTitle {
            font-size: 16px;
            font-weight: 600;
        }

        .salesInfo {
            text-align: right;

            h2 {
                font-size: 20px;
                font-weight: bold;
                margin: 0;

                .percentage {
                    font-size: 10px;
                    color: #58da37;
                    font-weight: bold;
                }

                .percentage::after {
                    content: '\2191';
                    font-size: 12px;
                }

                .negative {
                    color: #999999 !important;
                }

                .negative::after {
                    content: '\2193';
                    font-size: 12px;
                }
            }

            p {
                font-size: 10px;
                color: #666666;
            }
        }
    }
}