@import '@styles/variables';

.tabsContainer {

    .tabHeaders {
        display: flex;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 10;
        /* Ensure the tabs stay above the content */
    }

    .tabsHeader {
        display: flex;
        position: sticky;
        top: 0;
        z-index: 10;
        /* Ensure the tabs stay above the content */
    }

    .tab {
        padding: 12px;
        width: auto;
        /* this allows the item to take its natural width */
        cursor: pointer;
        font-size: 1.6rem;
        text-align: center;
        transition: 0.3s ease;
        background: #f8f9fa;

        &:hover {
            background: #e9ecef;
        }
    }

    .activeTab {
        @extend .tab;
        background: $cerulean;
        color: white;
        font-weight: bold;
    }

    .tabContent {
        margin-top: 15px;
        font-size: 16px;
        color: #333;
    }

}