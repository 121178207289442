$font_nunitoSans: 'Nunito Sans', sans-serif;
$font_ttNorms: 'TT Norms', sans-serif;
$font_ttNormsRegular: 'TTNorms-Regular', sans-serif;
$font_ttNormsBold: 'TTNorms-Bold', sans-serif;
$font_stoneSerifRegular: 'StoneSerifStd-Medium';
$font_stoneSerifSemibold: 'StoneSerifStd-Semibold';

$geyser: #e0e5e7;
$river_bed1: #475260;
$secondaryButtonColor: #666666;

$white: #fff;
$black: #000000;
$heavy_metal: #1d1d1b;
$ebony_clay: #213036;

$river_bed2: #3f4a59;
$mine_shaft: #242424;
$cod_gray: #161615;
$porcelain: #f0f3f3;
$cascade1: #8ca4aa;
$cascade2: #8da5ab;
$cascade3: #8ea6ac;
$loblolly: #c6cfd3;

$tradewind: #5dbbad;
$surfie_green: #0c8778;
$spray: #7aebda;

$cerulean: #009de1;
$denim: #197ada;
$endeavour: #0056b3;

$selective_yellow: #f9b200;

$geebung: #d48921;
$apple: #67a141;
$shiraz: #bb0e1a;
$regent_gray: #8d9ea3;

$fire-bush: #e5943e;
$mantis: #81b954;
$summer-sky: #3d9cdd;
$london-hue: #a09b9f;

$monza: #e10613;

$carnation: #f64e60;

$punch: #dc3545;

$persian_plum: #721c24;
$pink: #ffc6cc;
$cherub: #f8d7da;
$azalea: #f5c6cb;

$success: #67a141;
$error: #bb0e1a;

$nomPrimaryColor:$cerulean;
$winPrimaryColor:$fire-bush;
$merchantViewPrimaryColor:#7a12f6;

$zindex-loader-bar: 100;
$zindex-modal: 99;
$zindex-overlay: 9;

$header-height: 103px;
$pad-wrap-y: 36px;

$icon-xs: 10px;
$icon-sm: 14px;
$icon-md: 24px;
$icon-xl: 32px;
$icon-xxxl: 102px;
